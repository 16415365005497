import Parse from "parse";

Parse.initialize(process.env.PARSE_APP_ID, "");
Parse.serverURL = process.env.PARSE_URL;

export const SET_UNSPLASH_IMAGES = "SET_UNSPLASH_IMAGES";
export const GET_UNSPLASH_IMAGES = "GET_UNSPLASH_IMAGES";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const SET_TRY_NOW_MODAL = "SET_TRY_NOW_MODAL";

export const setTryNowModal = (payload) => ({
  type: SET_TRY_NOW_MODAL,
  payload,
});

export const searchUnsplashImages = (payload) => ({
  type: GET_UNSPLASH_IMAGES,
  payload,
});

export const setUnsplashImages = (payload) => ({
  type: SET_UNSPLASH_IMAGES,
  payload,
});

export const getUnsplash = (payload) => {
  return async (dispatch) => {
    try {
      const response = await Parse.Cloud.run("Service:searchUnsplash", {
        query: payload.query,
      });

      dispatch(setUnsplashImages({ images: response }));
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const createCampaign = (payload) => {
  return async (dispatch) => {
    try {
      const response = await Parse.Cloud.run("Marketing:CreateSampleCampaign", {
        cardOne: payload.cardOne,
        cardTwo: payload.cardTwo,
      });
      return { link: response.link };
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const sendCampaignTextMessage = (payload) => {
  return async (dispatch) => {
    try {
      await Parse.Cloud.run("Marketing:SendSampleCampignText", {
        phoneNumber: payload.phoneNumber,
        link: payload.link,
      });
      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const createNewAccount = (payload) => {
  return async (dispatch) => {
    try {
      const res = await Parse.Cloud.run("Marketing:SignUp", {
        phoneNumber: payload.phoneNumber,
        firstName: payload.firstName,
        lastName: payload.lastName,
        business: payload.business,
        email: payload.email,
        password: payload.password,
      });
      return true;
    } catch (err) {
      console.log("Error called immediatley");
      console.log(err);
      throw err;
    }
  };
};
