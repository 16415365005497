import React, {useEffect} from "react";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";

const TopLayout = ({ children, theme }) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    }
  }, []);

  return (
    <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
  );
}

export default TopLayout;
