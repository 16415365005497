import {
  SET_TRY_NOW_MODAL,
  SET_UNSPLASH_IMAGES
} from '../actions/mainActions';

const INITIAL_STATE = {
  unsplashImages: null,
  createCampaignLoading: false,
  tryNowModalOpened: false,
};

const setUnsplashImages = (state, action) => ({
  ...state,
  unsplashImages: action.payload.images
});

function mainReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TRY_NOW_MODAL: {
      return {
        ...state,
        tryNowModalOpened: action.payload
      }
    }
    case SET_UNSPLASH_IMAGES: {
      return setUnsplashImages(state, action);
    }
    default: {
      return state;
    }
  }
}

export default mainReducer;
