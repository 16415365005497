import { createMuiTheme } from "@material-ui/core";
import { Colors } from "../constants/colors";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Open Sans"
  },
  palette: {
    text: {
      primary: Colors.font
    },
    primary: {
      main: Colors.primary
    }
  }
});

export default theme;
