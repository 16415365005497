// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-try-now-js": () => import("./../../../src/pages/try-now.js" /* webpackChunkName: "component---src-pages-try-now-js" */),
  "component---src-pages-why-tappshare-js": () => import("./../../../src/pages/why-tappshare.js" /* webpackChunkName: "component---src-pages-why-tappshare-js" */)
}

